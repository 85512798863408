import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import UrlHelpers from "../../../helpers/Url";
import StarsRating from "../../StarsRating";
import TracksTableHeader from "../Tracks/TracksTableHeader";
import TracksTableItemWithHeaderPlayer from "../Player/TracksTableItemWithHeaderPlayer";
import * as tracksUtils  from "../../../utils/tracks";
import TracksGrid from "../Tracks/TracksGrid";
import connect from "react-redux/es/connect/connect";
import {calcRatingByUser} from "../../../utils/tracks";
import UserHelpers from "../../../helpers/User";
import {bindActionCreators} from "redux";
import {selectCompilation, viewCompilation, changeCompilationTracksViewType, deselectCompilation,
  rateCompilation, createPlaylistFromCompilation
} from "../../../actions/compilations";
import {addTrackToPlaylist, removeTrackFromPlaylist, startPlayingTrack, stopPlayingTrack, updateCurrentTimeForPlayingTrack,
  fetchTrackPeaks, toggleLoader, updatePlaylist
} from "../../../actions/catalog";
import {renderDuration} from "../../../utils/playlists";

import {ReactComponent as TableIcon} from '../../../icons/view_list.svg';
import {ReactComponent as GridIcon} from '../../../icons/view_module.svg';
import {toLocaleDateString} from "../../../utils/dates";

class Compilation extends Component {

  constructor(props, context) {
    super(props, context);
    this.createPlaylistFromCompilation = this.createPlaylistFromCompilation.bind(this);
    this.addTracksToPlaylist = this.addTracksToPlaylist.bind(this);
    this.rateCompilation = this.rateCompilation.bind(this);
    this.state = {
      activeCompilationRating: props.activeCompilation ? props.activeCompilation.rating : 0
    };
  }

  componentWillUnmount() {
    this.props.deselectCompilation();
  }

  componentWillMount() {
    const {viewCompilation, selectCompilation, match, peaks} = this.props;
    const id = match.params.id;
    viewCompilation(id);
    selectCompilation(id, peaks);
  }

  createPlaylistFromCompilation() {
    this.props.createPlaylistFromCompilation(this.props.activeCompilation.selectionId);
  }

  addTracksToPlaylist() {
    const {playlist, activeCompilation} = this.props;
    let tracksPlaylistData = [];
    if (playlist) {
      activeCompilation.trackSelections.forEach(function(track) {
        tracksPlaylistData.push({
          trackId: track.trackId,
          playListId: playlist.playListId
        });
      });
      playlist.trackPlayLists.forEach(function(track) {
        tracksPlaylistData.push({
          trackId: track.trackId,
          playListId: playlist.playListId
        });
      });
      this.props.updatePlaylist(playlist.name, playlist.timeOfDay, playlist, tracksPlaylistData);
    }
  }

  rateCompilation(rateIndex) {
    const {rateCompilation, activeCompilation} = this.props;
    this.setState({
      activeCompilationRating: calcRatingByUser(activeCompilation.rating, rateIndex, activeCompilation.ratingCount)
    }, () => {
      rateCompilation(rateIndex, activeCompilation.selectionId);
    });
  }

  render() {
    const {deselectCompilation, activeCompilation, addTrackToPlaylist, removeTrackFromPlaylist, startPlayingTrack, stopPlayingTrack,
      updateCurrentTimeForPlayingTrack, fetchTrackPeaks, toggleLoader, playlist,
      playingWavesurfer, playingTrack, isPlayingTrackActive, playingTrackSource, peaks, changeCompilationTracksViewType, tracksViewType} = this.props;

    const genreSelectionsCount = activeCompilation ? activeCompilation.genreSelections.length : 0;
    return activeCompilation ? (
      <div className="catalogue">
        <div className="compilation-inside">
          <div className="compilation-inside__image">
            <img src={UrlHelpers.baseImg() + '/' + activeCompilation.imageFile} />
          </div>
          <div className="compilation-inside__textblock">
            <p className="compilation-inside__text compilation-inside__text_big compilation-inside__text_title">{activeCompilation.name}</p>
            <p className="compilation-inside__text compilation-inside__text_medium compilation-inside__text_genre">Жанр:
              <b>&nbsp;{activeCompilation.genreSelections.map((g, i) =>
                <span key={i}>{g.name}{genreSelectionsCount < (i - 1) && ','}&nbsp;</span>
              )}</b>
            </p>
            <p className="compilation-inside__text">Дата публикации:
              <b>&nbsp;{toLocaleDateString(activeCompilation.createDate)}</b>
            </p>
            <p className="compilation-inside__text">Продолжительность:
              <b>&nbsp;{renderDuration(activeCompilation.duration)}</b>
            </p>
            <p className="compilation-inside__text compilation-inside__text_inline">Просмотров:
              <b>&nbsp;{activeCompilation.views}</b>
            </p>
            <p className="compilation-inside__text compilation-inside__text_inline">Треков:
              <b>&nbsp;{activeCompilation.trackSelections.length}</b>
            </p>
            <p className="compilation-inside__text">Последние изменение:
              <b>&nbsp;{toLocaleDateString(activeCompilation.changeDate)}</b>
            </p>
          </div>
          <div className="compilation-right compilation-inside__compilation-right">
            <div className="rate-card">
              <div className="rate-card__rate">
                <span className="rate-card__rate-text">Оцените эту подборку</span>
                <div className="rating-stars rating-stars_big rate-card__stars">
                  <StarsRating rating={this.state.activeCompilationRating} className="track-table-item__rating"
                               onClickCallback={this.rateCompilation}/>
                </div>
              </div>
              <span className="rate-card__value">{activeCompilation.rating.toFixed(1)}</span>
            </div>
            <p className="compilation-right__text">{activeCompilation.description}</p>
          </div>
        </div>
        <div className="tag-compilation catalogue__tags">
          <div className="tag-compilation__col">
            <span className="tag-compilation__title">Подойдет для:</span>
          </div>
          <div className="tag-compilation__col">
            {
              activeCompilation.companyTypeSelections.map((ct, i) =>
                <a className="tag tag-compilation__tag" key={i}>{ct.name}</a>
              )
            }
          </div>
        </div>
        <div className="compilation-control catalogue__compilation-controls">
          <button className="button button_color_blue compilation-control__button"
                  onClick={this.createPlaylistFromCompilation}>
            СОЗДАТЬ ПЛЕЙЛИСТ ИЗ ПОДБОРКИ
          </button>
          <button className={"button compilation-control__button " + (playlist ? 'button_color_blue' : 'button_color_light-grey')}
                  onClick={this.addTracksToPlaylist}>
            ПЕРЕНЕСТИ ВСЕ ТРЕКИ В ТЕКУЩИЙ ПЛЕЙЛИСТ
          </button>
          <Link to='/compilations' className="button button_color_blue compilation-control__button">ВЕРНУТЬСЯ К ПОДБОРКАМ</Link>
          <div className="view-toggler compilation-control__toggler">
            <a className={"view-toggler__tab " + (tracksUtils.isGridTracksView(tracksViewType) ? "view-toggler__tab_active": "")}
               onClick={changeCompilationTracksViewType.bind(this, 'grid')}>
              <GridIcon className="view-toggler__icon svg-icon" />
            </a>
            <a className={"view-toggler__tab " + (tracksUtils.isTableTracksView(tracksViewType) ? "view-toggler__tab_active": "")}
               onClick={changeCompilationTracksViewType.bind(this, 'table')}>
              <TableIcon className="view-toggler__icon svg-icon" />
            </a>
          </div>
        </div>
        {tracksUtils.isTableTracksView(tracksViewType) &&
          <div className="track-table catalogue__table">
            <TracksTableHeader />
            {activeCompilation.trackSelections.map((track, i) =>
              <TracksTableItemWithHeaderPlayer key={track.trackId}
                                               track={track}
                                               addTrackToPlaylist={addTrackToPlaylist}
                                               removeTrackFromPlaylist={removeTrackFromPlaylist}
                                               playlist={playlist}
                                               startPlayingTrack={startPlayingTrack}
                                               stopPlayingTrack={stopPlayingTrack}
                                               playingWavesurfer={playingWavesurfer}
                                               playingTrack={playingTrack}
                                               isPlayingTrackActive={isPlayingTrackActive}
                                               playingTrackSource={playingTrackSource}
                                               updateCurrentTimeForPlayingTrack={updateCurrentTimeForPlayingTrack}
                                               peaks={peaks}
                                               fetchTrackPeaks={fetchTrackPeaks}
                                               toggleLoader={toggleLoader}
              />
            )}
          </div>
        }

        {tracksUtils.isGridTracksView(tracksViewType) &&
          <TracksGrid tracks={activeCompilation.trackSelections} addTrackToPlaylist={addTrackToPlaylist}
                      removeTrackFromPlaylist={removeTrackFromPlaylist}
                      playlist={playlist}
                      startPlayingTrack={startPlayingTrack}
                      stopPlayingTrack={stopPlayingTrack}
                      playingWavesurfer={playingWavesurfer}
                      playingTrack={playingTrack}
                      isPlayingTrackActive={isPlayingTrackActive}
                      playingTrackSource={playingTrackSource}
                      updateCurrentTimeForPlayingTrack={updateCurrentTimeForPlayingTrack}
                      fetchTrackPeaks={fetchTrackPeaks}
                      peaks={peaks}
                      toggleLoader={toggleLoader}
          />
        }

      </div>
    ) : null
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    viewCompilation: bindActionCreators(viewCompilation, dispatch),
    selectCompilation: bindActionCreators(selectCompilation, dispatch),
    changeCompilationTracksViewType: bindActionCreators(changeCompilationTracksViewType, dispatch),
    addTrackToPlaylist: bindActionCreators(addTrackToPlaylist, dispatch),
    removeTrackFromPlaylist: bindActionCreators(removeTrackFromPlaylist, dispatch),
    startPlayingTrack: bindActionCreators(startPlayingTrack, dispatch),
    stopPlayingTrack: bindActionCreators(stopPlayingTrack, dispatch),
    updateCurrentTimeForPlayingTrack: bindActionCreators(updateCurrentTimeForPlayingTrack, dispatch),
    fetchTrackPeaks: bindActionCreators(fetchTrackPeaks, dispatch),
    toggleLoader: bindActionCreators(toggleLoader, dispatch),
    deselectCompilation: bindActionCreators(deselectCompilation, dispatch),
    rateCompilation: bindActionCreators(rateCompilation, dispatch),
    createPlaylistFromCompilation: bindActionCreators(createPlaylistFromCompilation, dispatch),
    updatePlaylist: bindActionCreators(updatePlaylist, dispatch)
  };
};

function mapStateToProps(state) {
  return {
    tracksViewType: state.compilations.tracksViewType,
    isPlayingTrackActive: state.catalog.isPlayingTrackActive,
    activeCompilation: state.compilations.activeCompilation,
    playlist: state.catalog.playlist,
    playingWavesurfer: state.catalog.playingWavesurfer,
    playingTrack: state.catalog.playingTrack,
    playingTrackSource: state.catalog.playingTrackSource,
    peaks: state.catalog.peaks
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Compilation);
