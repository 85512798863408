import {
  GET_TRACKS_LIST,
  GET_TRACKS_LIST_SUCCESS,
  GET_TRACKS_LIST_FAILURE,
  GET_SEARCH_TRACKS_LIST,
  GET_SEARCH_TRACKS_LIST_SUCCESS,
  GET_SEARCH_TRACKS_LIST_FAILURE,
  FETCH_PEAKS_SUCCESS,
  TOGGLE_TRACKS_VIEW,
  GET_GENRES_SUCCESS,
  GET_GENRES_FAILURE,
  TOGGLE_OPENED_TRACKS_FILTER,
  CREATE_PLAYLIST,
  CANCEL_CREATE_PLAYLIST,
  GET_PLAYLISTS_SUCCESS,
  GET_PLAYLISTS_FAILURE,
  SAVE_PLAYLIST,
  SAVE_PLAYLIST_SUCCESS,
  SAVE_PLAYLIST_FAILURE,
  SHOW_PLAYLIST_SUCCESS,
  SHOW_PLAYLIST_FAILURE,
  EDIT_PLAYLIST,
  CANCEL_UPDATE_PLAYLIST,
  DELETE_PLAYLIST_SUCCESS,
  DELETE_PLAYLIST_FAILURE,
  UPDATE_PLAYLIST_FAILURE,
  ADD_TRACK_TO_PLAYLIST_FAILURE,
  GET_COMPANY_TYPES_SUCCESS,
  GET_COMPANY_TYPES_FAILURE,
  GET_CHANNELS_SUCCESS,
  GET_CHANNELS_FAILURE,
  ADD_GENRE_TRACKS_FILTER,
  DELETE_GENRE_TRACKS_FILTER,
  ADD_COMPANY_TYPE_FILTER,
  DELETE_COMPANY_TYPE_FILTER,
  SORT_TRACKS_BY,
  CHANGE_SORT_TRACKS_DIRECTION,
  REMOVE_TRACKS_SORT,
  START_PLAYING_TRACK,
  STOP_PLAYING_TRACK,
  CLEAR_PLAYING_TRACK,
  LIMIT_TRACKS,
  UPDATE_CURRENT_TIME_PLAYING_TRACK,
  TOGGLE_LOADER,
  DESELECT_PLAYLIST,
  TOGGLE_SEARCH_FETCH_TRACKS_LOADER,
  TOGGLE_FETCH_TRACKS_LOADER,
  ADD_PLAYBACK_STATISTICS
} from '../actions/catalog';


const INITIAL_STATE = {tracksLoading: true, loading: false, tracks: [], allTracksLoaded: true, peaks: {}, peaksLength: 0, tracksView: 'grid',
                        genres: [], companyTypes: [], tracksFilter: '',
                        searchTracksLoading: false, searchTracks: [],
                        playlistAction: 'LIST', playlists: [], playlist: null,
                        genresFilter: [], companyTypeFilter: [], sortTracksBy: '', tracksSortDirection: 'asc',
                        playingWavesurfer: null, playingTrack: {}, isPlayingTrackActive: false, playingTrackSource: null,
                        currentTimePlayingTrack: 0, playingTrackIndex: -1};

export default function(state = INITIAL_STATE, action) {
  switch(action.type) {
    case TOGGLE_FETCH_TRACKS_LOADER:
      return action.payload.clearTracks ?
        {...state, tracksLoading: action.payload.show, tracks: []} :
        {...state, tracksLoading: action.payload.show};
    case TOGGLE_SEARCH_FETCH_TRACKS_LOADER:
      return action.payload.clearTracks ?
        {...state, searchTracksLoading: action.payload.show, searchTracks: []} :
        {...state, searchTracksLoading: action.payload.show};
    case TOGGLE_LOADER:
      return {...state, loading: action.payload};
    case GET_TRACKS_LIST:
      return { ...state, tracks: [], error: null};
    case GET_TRACKS_LIST_SUCCESS:
      return { ...state, tracks: action.payload.data, error: null,
        allTracksLoaded: action.payload.allTracksLoaded};
    case GET_TRACKS_LIST_FAILURE:
      return { ...state, tracks: [], error: action.payload};
    case GET_SEARCH_TRACKS_LIST:
      return { ...state, searchTracks: [], error: null};
    case GET_SEARCH_TRACKS_LIST_SUCCESS:
      return { ...state, searchTracks: action.payload, error: null};
    case GET_SEARCH_TRACKS_LIST_FAILURE:
      return { ...state, searchTracks: [], error: action.payload};
    case FETCH_PEAKS_SUCCESS:
      let peaks = Object.assign({}, state.peaks);
      peaks[action.payload.trackId] = action.payload.data;
      return { ...state, peaks: peaks, peaksLength: Object.keys(peaks).length};
    case ADD_GENRE_TRACKS_FILTER:
      let genresFilter = state.genresFilter;
      genresFilter.push(action.payload);
      return { ...state, genresFilter: genresFilter};
    case DELETE_GENRE_TRACKS_FILTER:
      let deleteGenresFilter = state.genresFilter;
      let index = deleteGenresFilter.indexOf(action.payload);
      if (index !== -1) deleteGenresFilter.splice(index, 1);
      return { ...state, genresFilter: deleteGenresFilter};
    case ADD_PLAYBACK_STATISTICS:
      return state;
    case ADD_COMPANY_TYPE_FILTER:
      let companyTypeFilter = state.companyTypeFilter;
      companyTypeFilter.push(action.payload);
      return { ...state, companyTypeFilter: companyTypeFilter};

    case DELETE_COMPANY_TYPE_FILTER:
      let deleteCompanyTypeFilter = state.companyTypeFilter;
      let companyTypeIndex = deleteCompanyTypeFilter.indexOf(action.payload);
      if (companyTypeIndex !== -1) deleteCompanyTypeFilter.splice(companyTypeIndex, 1);
      return { ...state, companyTypeFilter: deleteCompanyTypeFilter};

    case SORT_TRACKS_BY:
      return { ...state, sortTracksBy: action.payload, tracksSortDirection: 'asc'};

    case CHANGE_SORT_TRACKS_DIRECTION:
      return { ...state, tracksSortDirection: action.payload};

    case REMOVE_TRACKS_SORT:
      return { ...state, sortTracksBy: '', tracksSortDirection: 'asc'};

    case TOGGLE_TRACKS_VIEW:
      return { ...state, tracksView: action.payload, loading: false};
    case TOGGLE_OPENED_TRACKS_FILTER:
      return { ...state, tracksFilter: action.payload};

    case GET_GENRES_SUCCESS:
      return { ...state, genres: action.payload};
    case GET_GENRES_FAILURE:
      return { ...state, genres: [], error: action.payload};

    case DESELECT_PLAYLIST:
      return {...state, playlistAction: 'LIST', playlist: null};
    case CREATE_PLAYLIST:
      return {...state, playlistAction: 'CREATE'};
    case CANCEL_CREATE_PLAYLIST:
      return {...state, playlistAction: 'LIST', playlist: null};
    case GET_PLAYLISTS_SUCCESS:
      return {...state, playlistAction: 'LIST', playlists: action.payload, playlist: null};
    case GET_PLAYLISTS_FAILURE:
      return { ...state, playlistAction: 'LIST', playlists: [], error: action.payload, playlist: null};
    case SAVE_PLAYLIST:
      return { ...state, playlistAction: 'LIST', playlist: null};
    case UPDATE_PLAYLIST_FAILURE:
      return {...state, playlistAction: 'EDIT', error: action.payload};
    case SAVE_PLAYLIST_SUCCESS:
      return {...state, playlistAction: 'LIST', playlist: null};
    case SAVE_PLAYLIST_FAILURE:
      return { ...state, playlistAction: 'CREATE', error: action.payload};
    case SHOW_PLAYLIST_SUCCESS:
      return { ...state, playlistAction: 'SHOW', playlist: action.payload};
    case SHOW_PLAYLIST_FAILURE:
      return { ...state, playlistAction: 'LIST', error: action.payload, playlist: null};
    case EDIT_PLAYLIST:
      return { ...state, playlistAction: 'EDIT', playlist: action.payload};
    case CANCEL_UPDATE_PLAYLIST:
      return {...state, playlistAction: 'SHOW'};
    case DELETE_PLAYLIST_SUCCESS:
      return {...state, playlistAction: 'LIST', playlist: null};
    case DELETE_PLAYLIST_FAILURE:
      return {...state, error: action.payload, playlist: null, playlistAction: 'LIST'};
    case ADD_TRACK_TO_PLAYLIST_FAILURE:
      return { ...state, error: action.payload, playlist: null, playlistAction: 'LIST'};

    case GET_COMPANY_TYPES_SUCCESS:
      return { ...state, companyTypes: action.payload};
    case GET_COMPANY_TYPES_FAILURE:
      return { ...state, companyTypes: [], error: action.payload};

    case GET_CHANNELS_SUCCESS:
      return { ...state, channels: action.payload};
    case GET_CHANNELS_FAILURE:
      return { ...state, channels: [], error: action.payload};

    case START_PLAYING_TRACK:
      return { ...state, playingWavesurfer: action.wavesurfer,
        playingTrack: action.playingTrack,
        isPlayingTrackActive: true,
        playingTrackSource: action.playingTrackSource,
        playingTrackIndex: action.playingTrackIndex
      };

    case STOP_PLAYING_TRACK:
      return { ...state, isPlayingTrackActive: false, loading: false};

    case CLEAR_PLAYING_TRACK:
      return { ...state, isPlayingTrackActive: false, playingTrack: {}, playingWavesurfer: null};

    case LIMIT_TRACKS:
      return { ...state, tracks: action.payload};

    case UPDATE_CURRENT_TIME_PLAYING_TRACK:
      return { ...state, currentTimePlayingTrack: action.time};

    default:
    return state;
  }
}
