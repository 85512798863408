import {api} from '../index';
import UserHelpers from "../helpers/User";
import {fetchPlaylistsCompilation, fetchAllTrackPeaks} from "./catalog";
import NProgress from "nprogress";

const Mubicon = require('@mubicon/api');

export const FETCH_COMPILATIONS_SUCCESS  = 'FETCH_COMPILATIONS_SUCCESS';
export const FETCH_COMPILATIONS_FAILURE = 'FETCH_COMPILATIONS_FAILURE';
export const CHANGE_COMPILATION_TRACKS_VIEW_TYPE = 'CHANGE_COMPILATION_TRACKS_VIEW_TYPE';
export const SELECT_COMPILATION_SUCCESS = 'SELECT_COMPILATION_SUCCESS';
export const SELECT_COMPILATION_FAILURE = 'SELECT_COMPILATION_FAILURE';
export const DESELECT_COMPILATION = 'DESELECT_COMPILATION';
export const CREATE_PLAYLIST_FROM_COMPILATION_FAILURE = 'CREATE_PLAYLIST_FROM_COMPILATION_FAILURE';
export const CREATE_PLAYLIST_FROM_COMPILATION_SUCCESS = 'CREATE_PLAYLIST_FROM_COMPILATION_SUCCESS';
export const VIEW_COMPILATION_SUCCESS = 'VIEW_COMPILATION_SUCCESS';

export function fetchCompilations() {
  const oAuth = new Mubicon.OAuth();
  oAuth.accessToken = UserHelpers.getAccessToken();
  api.setDefaultAuthentication(oAuth);

  return dispatch => {
    return api.getSelections().then((compilations) => {
      return dispatch(fetchCompilationsSuccess(compilations));
    }).catch((err) => {
      return dispatch(fetchCompilationsFailure(err));
    });
  };
}

export function fetchCompilationsSuccess(compilations){
  return (dispatch) => {
    dispatch({
      type: FETCH_COMPILATIONS_SUCCESS,
      payload: compilations.body
    });
  };
}

export function fetchCompilationsFailure(err){
  err.response.body.errors.forEach((e) => {
    UserHelpers.showError(e.errorMessage);
  });
  return UserHelpers.handleAuthError(err.response, (dispatch) => {
    dispatch({
      type: FETCH_COMPILATIONS_FAILURE,
      payload: err
    });
  });
}

export function selectCompilation(compilationId, peaks) {
  const oAuth = new Mubicon.OAuth();
  oAuth.accessToken = UserHelpers.getAccessToken();
  api.setDefaultAuthentication(oAuth);

  NProgress.start();
  return dispatch => {
    return api.getSelectionsById(compilationId).then((compilation) => {
      NProgress.done();
      dispatch(fetchAllTrackPeaks(compilation.body.trackSelections, peaks));
      return dispatch(selectCompilationSuccess(compilation));
    }).catch((err) => {
      NProgress.done();
      return dispatch(selectCompilationFailure(err));
    });
  };
}

export function deselectCompilation() {
  return (dispatch) => {
    dispatch({
      type: DESELECT_COMPILATION
    });
  };
}

export function selectCompilationSuccess(compilation) {
  return (dispatch) => {
    dispatch({
      type: SELECT_COMPILATION_SUCCESS,
      payload: compilation.body
    });
  };
}

export function selectCompilationFailure(err) {
  err.response.body.errors.forEach((e) => {
    UserHelpers.showError(e.errorMessage);
  });
  return UserHelpers.handleAuthError(err.response, (dispatch) => {
    dispatch({
      type: SELECT_COMPILATION_FAILURE,
      payload: err
    });
  });
}

export function changeCompilationTracksViewType(viewType) {
  return (dispatch) => {
    dispatch({
      type: CHANGE_COMPILATION_TRACKS_VIEW_TYPE,
      payload: viewType
    });
  };
}

export function rateCompilation(rateIndex, compilationId) {
  const oAuth = new Mubicon.OAuth();
  oAuth.accessToken = UserHelpers.getAccessToken();
  api.setDefaultAuthentication(oAuth);

  return dispatch => {
    return api.putSelectionsByIdRatingByNewRatingValue(compilationId, rateIndex);
  };
}


export function viewCompilation(id) {
  const oAuth = new Mubicon.OAuth();
  oAuth.accessToken = UserHelpers.getAccessToken();
  api.setDefaultAuthentication(oAuth);

  return dispatch => {
    return api.putSelectionsByIdViews(id).then(() => {
      return dispatch(viewCompilationSuccess());
    });
  };
}

export function viewCompilationSuccess() {
  return (dispatch) => {
    dispatch({
      type: VIEW_COMPILATION_SUCCESS
    });
  };
}

export function createPlaylistFromCompilation(compilationId) {
  const oAuth = new Mubicon.OAuth();
  oAuth.accessToken = UserHelpers.getAccessToken();
  api.setDefaultAuthentication(oAuth);

  return dispatch => {
    return api.postSelectionsById(compilationId).then(() => {
      UserHelpers.showSuccess('Плейлист создан.');
      return dispatch(fetchPlaylistsCompilation());
    }).catch((err) => {
      return dispatch(createPlaylistFromCompilationFailure(err));
    });
  };
}

export function createPlaylistFromCompilationFailure(err) {
  err.response.body.errors.forEach((e) => {
    UserHelpers.showError(e.errorMessage);
  });
  return UserHelpers.handleAuthError(err.response,  (dispatch) => {
    dispatch({
      type: CREATE_PLAYLIST_FROM_COMPILATION_FAILURE,
      payload: err
    });
  });
}

