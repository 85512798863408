import {api} from '../index';
import UserHelpers from "../helpers/User";
import moment from "moment";
import {getCurrentSelectedFeedId, isFeedContainsPlaylist, setSavedFeed, getSavedFeed, removeSavedFeed} from "../utils/feeds";
const Mubicon = require('@mubicon/api');

export const REMOVE_FEED_SUCCESS = 'REMOVE_FEED_SUCCESS';
export const REMOVE_FEED_FAILURE = 'REMOVE_FEED_FAILURE';

export const FETCH_FEEDS_SUCCESS = 'FETCH_FEEDS_SUCCESS';
export const FETCH_FEEDS_FAILURE = 'FETCH_FEEDS_FAILURE';
export const SELECT_FEED = 'SELECT_FEED';
export const SELECT_FEED_FAILURE = 'SELECT_FEED_FAILURE';

export const SAVE_FEED_SUCCESS = 'SAVE_FEED_SUCCESS';
export const SAVE_FEED_FAILURE = 'SAVE_FEED_FAILURE';

export const CHANGE_ACTIVE_FEED_DAY_TYPE = 'CHANGE_ACTIVE_FEED_DAY_TYPE';

export const UPDATE_FEED_PLAYLIST_FAILURE = 'UPDATE_FEED_PLAYLIST_FAILURE';
export const UPDATE_FEED_PLAYLIST_SUCCESS = 'UPDATE_FEED_PLAYLIST_SUCCESS';
export const START_PLAYING_GENERAL_FEED_PLAYLIST = 'START_PLAYING_GENERAL_FEED_PLAYLIST';
export const START_PLAYING_GENERAL_FEED_PLAYLIST_ERR = 'START_PLAYING_GENERAL_FEED_PLAYLIST_ERR';

export const START_PLAYING_GENERAL_FEED_TRACK_ERR = 'START_PLAYING_GENERAL_FEED_TRACK_ERR';
export const START_PLAYING_GENERAL_FEED_TRACK = 'START_PLAYING_GENERAL_FEED_TRACK';
export const START_PLAYING_FEED_TRACK = 'START_PLAYING_FEED_TRACK';
export const STOP_PLAYING_FEED_TRACK = 'STOP_PLAYING_FEED_TRACK';

export const UPDATE_CURRENT_TIME_PLAYING_FEED_TRACK = 'UPDATE_CURRENT_TIME_PLAYING_FEED_TRACK';
export const CLEAR_PLAYING_FEED_TRACK = 'CLEAR_PLAYING_FEED_TRACK';

export const TOGGLE_FEED_LOADER= 'TOGGLE_FEED_LOADER';

export const GET_GENERAL_FEED_FAILURE = 'GET_GENERAL_FAILURE';
export const GET_GENERAL_FEED_SUCCESS = 'GET_GENERAL_FEED_SUCCESS';

export const START_GENERAL_FEED = 'START_GENERAL_FEED';
export const STOP_GENERAL_FEED = 'STOP_GENERAL_FEED';


export function removeFeed(feed) {
  const oAuth = new Mubicon.OAuth();
  oAuth.accessToken = UserHelpers.getAccessToken();
  api.setDefaultAuthentication(oAuth);


  return (dispatch, getState) => {
    return api.deleteFeedsById(feed.feedId).then((_) => {
      const savedFeedId = getSavedFeed();
      let toRemoveSavedFeed = false;
      if (feed.feedId.toString() === savedFeedId) {
        removeSavedFeed();
        toRemoveSavedFeed = true;
      }

      const {generalHeaderFeed, feeds} = getState().feed;

      const updatedFeeds = feeds.filter(item => item.feedId !== feed.feedId);
      const newActiveFeedId = getCurrentSelectedFeedId(updatedFeeds);
      const newActiveFeed = feeds.find(item => item.feedId === newActiveFeedId);

      UserHelpers.showSuccess('Лента удалена.');

      dispatch(removeFeedSuccess({activeFeed: newActiveFeed, feeds: updatedFeeds}));

      if (newActiveFeed.feedId !== generalHeaderFeed.feedId) {
        dispatch(clearPlayingFeedTrack());
        dispatch(selectFeed(true, newActiveFeedId));
        dispatch(getGeneralFeedPlay(newActiveFeedId));
      }
    }).catch((err) => {
      return dispatch(removeFeedFailure(err));
    });
  };
}

export function removeFeedSuccess({activeFeed, feeds}) {
  // const savedFeedId = getSavedFeed();
  // let toRemoveSavedFeed = false;
  // if (feed.feedId.toString() === savedFeedId) {
  //   removeSavedFeed();
  //   toRemoveSavedFeed = true;
  // }

  return (dispatch) => {
    dispatch({
      type: REMOVE_FEED_SUCCESS,
      payload: feeds,
      activeFeed
    });
  };
}

export function removeFeedFailure(error) {
  // UserHelpers.showError("Проблема с загрузкой лент, перезагрузите страницу.");
  return UserHelpers.handleAuthError(error.response, (dispatch) => {
    dispatch({
      type: REMOVE_FEED_FAILURE,
      payload: error
    });
  });
}

export function fetchFeeds(needSelectFeed, selectFeedId) {
  const oAuth = new Mubicon.OAuth();
  oAuth.accessToken = UserHelpers.getAccessToken();
  api.setDefaultAuthentication(oAuth);


  return dispatch => {
    return api.getFeeds().then((data) => {
      if (!selectFeedId) {
        selectFeedId = getCurrentSelectedFeedId(data.body);
      }
      return dispatch(fetchFeedsSuccess(data));
    }).then(() => {
      dispatch(selectFeed(needSelectFeed, selectFeedId));
      return dispatch(getGeneralFeedPlay(selectFeedId));
    }).catch((err) => {
      return dispatch(fetchFeedsFailure(err));
    });
  };
}

export function fetchFeedsSuccess(response) {
  return (dispatch) => {
    dispatch({
      type: FETCH_FEEDS_SUCCESS,
      feeds: response.body
    });
  };
}

export function fetchFeedsFailure(error) {
  // UserHelpers.showError("Проблема с загрузкой лент, перезагрузите страницу.");
  return UserHelpers.handleAuthError(error.response, (dispatch) => {
    dispatch({
      type: FETCH_FEEDS_FAILURE,
      payload: error
    });
  });
}

export function saveFeed(name) {
  const oAuth = new Mubicon.OAuth();
  oAuth.accessToken = UserHelpers.getAccessToken();
  api.setDefaultAuthentication(oAuth);
  return dispatch => {
    return api.postFeeds({name: name}).then((response) => {
      UserHelpers.showSuccess('Лента добавлена.');
      return dispatch(fetchFeeds(true, response.body.FeedId));
    }).catch((err) => {
      return dispatch(saveFeedFailure(err));
    });
  };
}

export function saveFeedFailure(error) {
  return UserHelpers.handleAuthError(error.response, (dispatch) => {
    dispatch({
      type: SAVE_FEED_FAILURE,
      payload: error
    });
  });
}

export function selectFeed(needSelectFeed, feedId) {
  if (needSelectFeed) {
    const oAuth = new Mubicon.OAuth();
    oAuth.accessToken = UserHelpers.getAccessToken();
    api.setDefaultAuthentication(oAuth);

    return dispatch => {
      return api.getFeedsById(feedId ? feedId : 0).then((response) => {
        return dispatch(selectFeedSuccess(response.body));
      }).catch((err) => {
        return dispatch(selectFeedFailure(err));
      });
    };
  }
}

export function selectFeedSuccess(feed) {
  return (dispatch) => {
    dispatch({
      type: SELECT_FEED,
      payload: feed
    });
  };
}

export function selectFeedFailure(error) {
  // UserHelpers.showError("Проблема с выбором ленты, попробуйте еще раз...");
  return UserHelpers.handleAuthError(error.response, (dispatch) => {
    dispatch({
      type: SELECT_FEED_FAILURE,
      payload: error
    });
  });
}

export function changeFeedDayType(dayType, feed, date) {
  const oAuth = new Mubicon.OAuth();
  oAuth.accessToken = UserHelpers.getAccessToken();
  api.setDefaultAuthentication(oAuth);
  let requestParams = {feedId: feed.feedId, name: feed.name, calendarDay: dayType};
  if (date) {
    requestParams.jsDate = moment.utc(date).format();
  }
  return dispatch => {
    return api.putFeeds(requestParams).then(() => {
      return dispatch(fetchFeeds(true, feed.feedId));
    }).catch((err) => {
      return dispatch(updateFeedFailure(err));
    });
  };
}

export function changeFeedDayTypeForm(type) {
  return (dispatch) => {
    dispatch({
      type: CHANGE_ACTIVE_FEED_DAY_TYPE,
      payload: type
    });
  };
}

export function toggleSwitchOffPlaylist(activeFeed, playlist) {
  const oAuth = new Mubicon.OAuth();
  oAuth.accessToken = UserHelpers.getAccessToken();
  api.setDefaultAuthentication(oAuth);
  const requestData = {
    playListId: playlist.playListId,
    feedId: activeFeed.feedId,
    active: !playlist.active,
    shuffle: playlist.shuffle
  };
  playlist.active = !playlist.active;
  return dispatch => {
    return api.putFeedsPlaylist(requestData).then(() => {
      return dispatch(fetchFeeds(true, activeFeed.feedId));
    }).catch((err) => {
      return dispatch(updateFeedFailure(err));
    });
  };
}

export function toggleRandomPlaylist(activeFeed, playlist) {
  const oAuth = new Mubicon.OAuth();
  oAuth.accessToken = UserHelpers.getAccessToken();
  api.setDefaultAuthentication(oAuth);
  const requestData = {
    playListId: playlist.playListId,
    feedId: activeFeed.feedId,
    shuffle: !playlist.shuffle,
    active: playlist.active
  };
  playlist.shuffle = !playlist.shuffle;
  return dispatch => {
    return api.putFeedsPlaylist(requestData).then(() => {
      return dispatch(fetchFeeds(true, activeFeed.feedId));
    }).catch((err) => {
      return dispatch(updateFeedFailure(err));
    });
  };
}

export function updateFeedFailure(error) {
  error.response.body.errors.forEach((e) => {
    UserHelpers.showError(e.errorMessage);
  });
  return UserHelpers.handleAuthError(error.response, (dispatch) => {
    dispatch({
      type: UPDATE_FEED_PLAYLIST_FAILURE,
      payload: error
    });
  });
}

export function updateFeedPlaylists(feed, togglePlaylist) {
  const oAuth = new Mubicon.OAuth();
  oAuth.accessToken = UserHelpers.getAccessToken();
  api.setDefaultAuthentication(oAuth);
  let playlistFeeds = [];

  let isContains = false;

  feed.playlists.map(function(pl) {
    if (pl.playListId !== togglePlaylist.playListId) {
      playlistFeeds.push({'playListId': pl.playListId, active: pl.active, shuffle: pl.shuffle});
    } else {
      isContains = true;
    }
  });

  if (!isContains) {
    playlistFeeds.push({'playListId': togglePlaylist.playListId, active: togglePlaylist.active, shuffle: togglePlaylist.shuffle});
  }

  const requestData = {feedId: feed.feedId, name: feed.name, 'playlistFeeds': playlistFeeds, calendarDay: feed.calendarDay};
  return dispatch => {
    return api.putFeeds(requestData).then(() => {
      return dispatch(fetchFeeds(true, feed.feedId));
    }).catch((err) => {
      return dispatch(updateFeedFailure(err));
    });
  };
}

export function changeFeedPlaylistPosition(playlist, activeFeed, index) {
  const oAuth = new Mubicon.OAuth();
  oAuth.accessToken = UserHelpers.getAccessToken();
  api.setDefaultAuthentication(oAuth);
  const requestData = {
    playListId: playlist.playListId,
    feedId: activeFeed.feedId,
    index: index,
    shuffle: playlist.shuffle,
    active: playlist.active
  };
  playlist.index = index;
  return dispatch => {
    return api.putFeedsPlaylist(requestData).then(() => {
      return dispatch(fetchFeeds(true, activeFeed.feedId));
    }).catch((err) => {
      return dispatch(updateFeedFailure(err));
    });
  };
}

export function removePlaylistFromFeed(playlist, activeFeed) {
  const oAuth = new Mubicon.OAuth();
  oAuth.accessToken = UserHelpers.getAccessToken();
  api.setDefaultAuthentication(oAuth);
  let ids = [];
  activeFeed.playlists.map(function(p){
    if (playlist.playListId !== p.playListId) {
      ids.push({'playListId': p.playListId, shuffle: p.shuffle, active: p.active});
    }
  });
  const requestData = {feedId: activeFeed.feedId, name: activeFeed.name, 'playlistFeeds': ids};
  return dispatch => {
    return api.putFeeds(requestData).then(() => {
      return dispatch(fetchFeeds(true, activeFeed.feedId));
    }).catch((err) => {
      return dispatch(updateFeedFailure(err));
    });
  };
}

export function startPlayingFeedTrack(playingTrack, wavesurfer, playlist, playingFeedTrackIndex) {
  const oAuth = new Mubicon.OAuth();
  oAuth.accessToken = UserHelpers.getAccessToken();
  api.setDefaultAuthentication(oAuth);

  return (dispatch) => {
    dispatch({
      type: START_PLAYING_FEED_TRACK,
      playingTrack: Object.assign({}, playingTrack),
      wavesurfer: wavesurfer,
      playingPlaylist: Object.assign({}, playlist),
      playingFeedTrackIndex: playingFeedTrackIndex
    });
  };
}

export function startPlayingGeneralFeedTrack(playingTrack) {
  if (!playingTrack) return (dispatch) => {
    dispatch({
      type: START_PLAYING_GENERAL_FEED_TRACK_ERR
    });
  };
  const oAuth = new Mubicon.OAuth();
  oAuth.accessToken = UserHelpers.getAccessToken();
  api.setDefaultAuthentication(oAuth);

  return (dispatch) => {
    dispatch({
      type: START_PLAYING_GENERAL_FEED_TRACK
    });
  };
}

export function startPlayingGeneralFeedPlaylist(playlist) {
  if(!playlist) return (dispatch) => {
    dispatch({
      type: START_PLAYING_GENERAL_FEED_PLAYLIST_ERR
    });
  };
  const oAuth = new Mubicon.OAuth();
  oAuth.accessToken = UserHelpers.getAccessToken();
  api.setDefaultAuthentication(oAuth);

  return (dispatch) => {
    api.putPlaylistsByIdPlaybacks(playlist.playListId).then(()=>{});
    dispatch({
      type: START_PLAYING_GENERAL_FEED_PLAYLIST
    });
  };
}

export function stopPlayingFeedTrack() {
  return (dispatch) => {
    dispatch({
      type: STOP_PLAYING_FEED_TRACK
    });
  };
}

export function clearPlayingFeedTrack(playingWavesurfer) {
  if (playingWavesurfer) {
    playingWavesurfer.stop();
    playingWavesurfer.destroy();
    playingWavesurfer = null;
  }
  return (dispatch) => {
    dispatch({
      type: CLEAR_PLAYING_FEED_TRACK
    });
  };
}

export function updateCurrentTimeForPlayingFeedTrack(time) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_CURRENT_TIME_PLAYING_FEED_TRACK,
      time: time
    });
  };
}

export function toggleLoader(show) {
  return (dispatch) => {
    dispatch({
      type: TOGGLE_FEED_LOADER,
      payload: show
    });
  };
}

export function getGeneralFeedPlayFailure(error) {
  return UserHelpers.handleAuthError(error.response, (dispatch) => {
    dispatch({
      type: GET_GENERAL_FEED_FAILURE,
      payload: error
    });
  });
}

export function getGeneralFeedPlaySuccess(feed) {
  return (dispatch) => {
    dispatch({
      type: GET_GENERAL_FEED_SUCCESS,
      payload: feed.body
    });
  };
}

export function getGeneralFeedPlay(id) {
  if (id && id !== '') {
    const oAuth = new Mubicon.OAuth();
    oAuth.accessToken = UserHelpers.getAccessToken();
    api.setDefaultAuthentication(oAuth);
    return dispatch => {
      return api.getFeedsByIdPlay(id).then((data) => {
        setSavedFeed(id);
        return dispatch(getGeneralFeedPlaySuccess(data));
      }).catch((err) => {
        return dispatch(getGeneralFeedPlayFailure(err));
      });
    };
  }
}

export function startGeneralFeed() {
  return (dispatch) => {
    dispatch({
      type: START_GENERAL_FEED
    });
  };
}

export function stopGeneralFeed() {
  return (dispatch) => {
    dispatch({
      type: STOP_GENERAL_FEED
    });
  };
}
